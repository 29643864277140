<template>
  <div class="strategy-param-block">
    <el-card class="hg-success">
      <span slot="header" class="text-white" style="font-size: 20px">
        <i class="fa fa-refresh"></i>
        {{ $t('syncwithstrattxt') }}
      </span>
      <div class="row" style="margin-bottom: 15px">
        <div class="form-group col-md-12">
          <p>
            {{ $t('syncwithstratmodaltxt') }}
          </p>
          <p-switch
            type="primary"
            loading="loadingSync"
            :disabled="disableSync"
            :value="syncManually"
            on-text="YES"
            off-text="NO"
            @input="handleToggleSync"
          />
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import PSwitch from "@/components/UIComponents/PSwitch.vue";

export default {
  components: {
    PSwitch
  },
  computed: {
    ...mapState('strategy_params', ['syncManually', 'accId', 'stratId']),
    disableSync () {
      return !this.canSync;
    }
  },
  data() {
    return {
      loadingSync: true,
      canSync: false,
    }
  },
  methods: {
    ...mapActions('strategy_params', [ 'toggleSyncManually' ]),
    confirmSyncStrat() {
      this.closeModal();
    },
    handleToggleSync() {
      this.toggleSyncManually();
    },
  },
  created () {
    this.$canSyncManually(this.accId)
      .then(resp => {
        this.canSync = !!(resp.data && resp.data['can_sync']);
        this.loadingSync = false;
      });
  },
}
</script>

<style scoped lang="scss">

</style>
